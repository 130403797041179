html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: #e3e2df !important;
  margin: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heebo {
  font-family: "Heebo", sans-serif;
}

.imp-w-bold {
  font-weight: bold !important;
}

.imp-w-thin {
  font-weight: 100 !important;
}

.rtl {
  direction: rtl;
}

.anti-nav {
  padding-top: 60px;
}
