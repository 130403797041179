.grades-mt {
  margin-top: 60px !important;
}

.title {
  font-family: "Heebo", sans-serif;
  font-weight: 800;
  line-height: 2.8rem;
  font-size: 3.5rem;
  color: rgba(68, 72, 80, 178.5);
  margin-top: 50px;
  margin-bottom: 50px;
}

.small-line {
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.grades-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.grade-box {
  background-color: #d1d0cc;
  width: 120px;
  height: 120px;
  padding: 20px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: 150ms;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.19),
    0px 5px 5px -2px rgba(0, 0, 0, 0.04);
}

.active-grade {
  background-color: #c7c5be;
  width: 130px;
  height: 130px;
  padding: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px !important;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: 150ms;
  box-shadow: 0px 8px 8px -2px rgba(0, 0, 0, 0.19),
    0px 5px 5px -2px rgba(0, 0, 0, 0.04);
}

.subtitle {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  font-size: 3rem;
  color: #222831;
  margin-top: 20px;
  margin-bottom: 70px !important;
  transition: 150ms;
}

.subtitle:hover {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  font-size: 3rem;
  color: #0a0c0e;
  margin-top: 20px;
  margin-bottom: 70px !important;
  transition: 150ms;
}

.dropdown-select {
  width: 120px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #d1d0cc;
  padding: 10px;
}

.blue-box {
  background-color: #d0d9df;
  padding: 10px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.1),
    0px 6px 6px -3px rgba(0, 0, 0, 0.04);
}

.title-in-box {
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: rgba(68, 72, 80, 178.5);
  margin-bottom: 0px;
}

.date-in-box {
  font-family: "Heebo", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0px;
  color: rgba(68, 72, 80, 178.5);
}

.teacher-text {
  font-family: "Arial", sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 0px;
  color: rgba(68, 72, 80, 178.5);
}

.subject-text {
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: rgba(68, 72, 80, 178.5);
  margin-bottom: 0px;
}

.vertical-line {
  width: 1px;
  height: 100%;
  background-color: #000000;
  margin-left: 10px;
  margin-right: 10px;
}

.title-box {
  margin-left: 10px;
  margin-right: 10px;
}

.exam-box {
  background-color: #1663a7;
}

.exam-text {
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 0px;
  padding-right: 3px;
}

.change-text-box {
  background-color: #16a71d;
}

.change-text {
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 0px;
  padding-right: 3px;
}
