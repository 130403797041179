.landing-area {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-title {
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  color: #1663a7;
}

.section-1 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.section-2 {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-right: 15px;
}

.subjects-paragraph {
  font-family: "Heebo", sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  color: #292929;
}

.s1-stats {
  margin: 10px !important;
}

.chart-container {
  margin: 30px !important;
  margin-left: 0px !important;
  padding: 20px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.section-title {
  font-family: "Heebo", sans-serif;
  font-weight: 800;
  line-height: 2.8rem;
  font-size: 2.8rem;
  color: #222831;
}

.stats-legend {
  text-align: center;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #222831;
}

.stats-legend:first-child {
  margin-top: 20px;
}

.scroll-info {
  position: absolute;
  bottom: 10px;
  left: 50%;
  right: 50%;
}

.pchart {
  height: 200px;
}

.chart-title {
  margin-top: 20px;
  text-align: center;
  font-family: "Heebo", sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  color: #222831;
}

.showcase-parent {
  /* The parent of .building-showcase */
  position: relative;
  height: 215px;
  left: -100px;
}

.scroll-info-details {
  font-family: "Heebo", sans-serif;
}

.building-showcase {
  position: absolute;
  height: 215px;
  opacity: 25%;
  transform: scaleX(-1);
}

.home-subtitle {
  margin-top: -40px;
  font-family: "Heebo", sans-serif;
  font-weight: 900;
  font-size: 2.75rem;
  color: #222831;
}

.footer {
  background-color: #d4d3cf;
  box-shadow: rgba(100, 100, 111, 0.25) 1px -5px 10px 5px;
}

.home-p {
  margin-top: -10px;
  font-family: "Heebo", sans-serif;
  font-weight: 200;
  font-size: 2rem;
  color: #222831;
}

.subject-list-btn {
  position: relative;
  font-family: "Heebo", sans-serif;
  font-size: 1.2rem;
  width: 250px;
  background-color: #dfe6eb;
  font-weight: 800;
  color: #434343;
  box-shadow: rgba(100, 100, 111, 0.5) 2px 3px 10px 0px;
  bottom: 0px;
  right: 0px;
  transition: 150ms;
}

.subject-list-btn:hover {
  box-shadow: rgba(100, 100, 111, 0.5) 4px 5px 10px 0px;
  bottom: 2px;
  right: 2px;
  transition: 150ms;
}

.section-4 {
  margin-top: 50px;
}

.promo-vid {
  position: relative;
  width: 90%;
  height: 0;
  padding-bottom: 50.6%;
  margin: auto;
  margin-bottom: 75px;
}

.promo-vid iframe {
  margin-top: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-shadow: rgba(100, 100, 111, 0.5) 4px 5px 10px 0px;
}
